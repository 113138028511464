import LandingPage from '../containers/LandingPage';

export default LandingPage;

export const pageQuery = graphql`
  query LandingPageEnUsQuery {
    site {
      siteMetadata {
        languages {
          langs
        }
      }
    }
    content: allContentfulLandingPage(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      ...LandingPageFragment
    }
  }
`;
