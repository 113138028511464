import React from 'react';
import * as PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from "react-intl";


import Layout from '../components/layout';
import SiteSearch from '../components/SiteSearch';

import "../styles/LandingPage.scss";

const propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const LandingPage = ({data, location}) => {

  const c = data.content.edges[0].node;
  const locale = c.node_locale;

  return (
    <Layout data={data} location={location}>
      <article className="LandingPage Page">
        <section className="container grid-lg">
          <div className="hero bg-white">
            <div className="hero-body">
              <h1>{c.heroTitle}</h1>
              <p>{c.heroParagraph}</p>
              <br />
              <SiteSearch />
            </div>
          </div>
        </section>
        <section className="container grid-lg">
          <h2 className="section-header text-center">
            <FormattedMessage id="featuredTopics" />
          </h2>
          <div className="LandingPage__topics cards">
            {c.featuredTopics.map((topic, idx) => (
              <div className={`card color-1`} key={idx}>
                <Link to={`/${locale}/topics/${topic.slug}`}>
                  <h1>{topic.name}</h1>
                  {/* <div className="card-header">
                    <h1 className="card-title">{topic.name}</h1>
                  </div>
                  <div className="card-body">
                    <p>{topic.summary.summary}</p>
                  </div>
                  <div className="card-footer"></div> */}
                </Link>
              </div>
            ))}
          </div>
        </section>
        <section className="container grid-lg">
          <h5>
            <FormattedMessage id="popularTerms" />
          </h5>
          <div className="LandingPage__terms cards">
            {c.featuredTerms.map((term, idx) => (
              <div className={`card color-5-alt`} key={idx}>
                <Link to={`/${locale}/terms/${term.slug}`}>
                  <h5>{term.title}</h5>
                </Link>
              </div>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  );
};

LandingPage.propTypes = propTypes;

export default LandingPage;

export const landingPageFragment = graphql`
  fragment LandingPageFragment on ContentfulLandingPageConnection {
    edges {
      node {
        node_locale
        heroTitle
        heroParagraph
        featuredTopics {
          name
          summary {
            summary
          }
          slug
        }
        featuredTerms {
          title
          slug
        }
      }
    }
  }
`;
